footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 800px;
  width: 100%;
  overflow: hidden;
  @media only screen and (max-width: 812px) {
    height: 1300px;
  }
  .copy-right {
    text-align: center;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      line-height: 1.5em;
      padding: .5em 0;
    }
  }
  .contact, .navigation{
    margin-bottom: 1em;
    padding: 0 1.5em;
  }
  .contact {
    line-height: 1.5em;
    padding: 0 1.5em;
  }
  a {
    &:hover {
      color: #777;
    }
  }
}
