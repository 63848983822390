html {
  position: relative;
  min-height: 100%;
}
body {
  background-color: #fff;
  color: #555;
  font-family: 'soleil', Arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 500px;
  min-height: 300px;
  padding-bottom: 300px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  @media only screen and (max-width: 812px) {
    min-height: 800px;
    padding-bottom: 800px;
  }
}
h1 {
  font-size: 55px;
  line-height: 1em;
  @media screen and (max-width:580px) {
    font-size: 220%;
  }
  @media screen and (max-width:450px) {
    font-size: 180%;
  }
}
h2 {
  @media screen and (max-width:580px) {
    font-size: 120%;
  }
}
h1, h2, h3, h4{
  font-weight: 700;
}
h3{
  font-size: 1.25em;
  line-height: 1.2em;
}
h4 {
  margin: .5em 0;
}
span.subhead {
  @extend h4;
  display: block;
  margin-top: 0;
}
.btn{
  background-color: $blueLinkColor;
  border-radius: 5px;
  color: #fff;
  font-size: .9em;
  padding: 1em;
  &:hover {
    background-color: $dkBlueLinkColor;
  }
}
p {
  padding: .25em 0;
}
a {
  color: $blueLinkColor;
  text-decoration: none;
  &:hover {
    color: $dkBlueLinkColor;
    text-decoration: none;
    cursor: pointer;
  }
}
.date, #copy-right {
  font-size: .9em;
  font-style: italic;
  margin: 0;
  padding: 0;
}
#copy-right {
  margin-top: 1em;
}
.fa-hotel{
  font-size: 2em;
}
.text-center {
  text-align: center;
}
.bg-gray {
  background-color: $ltGray;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
  padding: 0 0 1em 0;
}
.inline-right-txt {
  display: inline;
  font-size: .75em;
  margin-left: .25em;
  margin-right: 1em;
}
.alert-bar {
  background-color: #79ac42;
  padding: 10px;
  z-index: 100;
  position: relative;
  color: #fff;
  p {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
}
