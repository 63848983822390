
.float-left{
  float: left;
}
.float-right{
  float: right;
}
.container {
  width: 100%;
}
.container-centered {
  margin: 0 auto 3em auto;
  width: 70%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.cta-block.row{
  padding: 2.5em;
}
.clear:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.row, .row-center{
  padding: 1em;
  h3{
    margin-top: 0;
  }
}
.col-2{
  display: flex;
  flex-direction: column;
  &.vertical-card {
    justify-content: center;
  }
}
.col-2, .col-3, .col-4, .five.col, .col-5, .col-6, .col-10-pct, .col-90-pct {
  float: left;
  position: relative;
}
.col-2-container, .col-3-container, .col-4-container, .col-5-container, .col-6-container, .col-2080-container{
  &:after{
    content: " ";
    clear: both;
    display: table;
    height: 0;
  }
}
.width-10 {
  width: 10%;
  margin: 0 auto;
}
.width-20 {
  width: 20%;
  margin: 0 auto;
}
.width-30 {
  width: 40%;
  margin: 0 auto;
}
.width-40 {
  width: 40%;
  margin: 0 auto;
}
.width-50 {
  width: 50%;
  margin: 0 auto;
  @media screen and (max-width:1028px) {
    width: 80%;
  }
}
.width-60 {
  width: 60%;
  margin: 0 auto;
}
.width-70 {
  width: 70%;
  margin: 0 auto;
}
.width-80 {
  width: 80%;
  margin: 0 auto;
}
.width-90 {
  width: 90%;
  margin: 0 auto;
}
.width-100 {
  width: 100%;
  margin: 0 auto;
}
.right-align{
  position: absolute;
  right: 0;
}
.col-2 {
  width: calc(50% - 1.25em);
}
.col-3{
  width: calc(33% - 1.25em);
}
.col-4 {
  width: calc(25% - 1.25em);
}
.col-5 {
  width: calc(20% - 1.25em);
}
.col-6 {
  width: calc(16% - 1.25em);
}
.col-10-pct{
  width: 10%;
}
.col-90-pct{
  width: 90%
}
.row-center {
  margin: 0 auto;
  padding: 2em 1em;
  width: calc(100% - 2em);
}
.dropdown-business {
  display: block;
  float: left;
}
@media only screen and (max-width: 768px) {
  [class*="col-"] {
    width: 100%;
  }
}
@supports (display: grid) {
  .dropdown-business {
    display: flex;
  }
  .col-2-container, .col-3-container, .col-4-container, .col-5-container, .col-6-container, .col-2080-container, .col-1090-container{
    display: grid;
  }
  .col-2, .col-3, .col-4, .five.col, .col-5, .col-6, .col-10-pct, .col-90-pct{
    float: none;
    width: auto;
  }
  .col-1090-container {
    grid-template-columns: 10% 90%;
    grid-gap: 1em;
    @media only screen and (max-width: 768px) {
      grid-template-columns: 20% 80%;
    }
  }
  .col-2-container {
    grid-template-columns: 50% 50%;
  }
  .col-2080-container {
    grid-template-columns: 20% 80%;
  }
  .col-3-container {
    grid-template-columns: 33% 33% 33%;
    grid-gap: .5em;
  }
  .col-4-container {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .col-5-container {
    grid-template-columns:  20% 20% 20% 20% 20%;
  }
  .col-6-container {
    grid-template-columns:  16% 16% 16% 16% 16% 16%;
  }
  .browse-by-card-container {
    display: grid;
    grid-template-columns: 25% 25%;
    justify-content: center;
  }
  .row-center {
    display: grid;
    grid-template-columns: 75%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  @media only screen and (max-width: 1420px){
    .filter-cards{
      .col-3-container {
        grid-template-columns: 50% 50%;
      }
    }
  }
  @media only screen and (max-width: 823px) {
    .browse-by-card-container {
      grid-template-columns: 100%;
    }
    [class*="col-"] {
      grid-template-columns: 100%;
    }
    .row-center {
      grid-template-columns: 100%;
      justify-content: center;
    }
    .filter-cards{
      .col-3-container {
        grid-template-columns: 100%;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .width-10, .width-20, .width-30, .width-40, .width-50, .width-60, .width-70, .width-80, .width-90, .width-100 {
    width: 80%;
  }
}
@media only screen and (max-width: 530px) {
  .width-10, .width-20, .width-30, .width-40, .width-50, .width-60, .width-70, .width-80, .width-90, .width-100 {
    width: 100%;
  }
}

.non-supported-browser{
  background: url("../images/404-image.jpg");
  height:100%;
  left:0;
  position:absolute;
  top:0;
  width:100%;
  &:before{
    background-color: rgba(0,0,0,.55);
    content:" ";
    display: block;
    height:100%;
    left:0;
    position: absolute;
    top:0;
    width:100%;
    z-index:0;
  }
  .header-container{
    color: #fff;
    margin: 0 5em;
    position: relative;
    text-align: center;
    top: 30%;
    h1{
      line-height: 1.5em;
    }
    h2{
      line-height: 2em;
    }
  }
}
