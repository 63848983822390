.home {
  .carousel {
    padding: 2em;
  }
}
.carousel {
  h3{
    font-size: 1.5em;
  }
.stacked-carousel{
    h3{
      font-size: 1.5em;
    }
    .card {
      float: none;
      width: auto;
    }
  }
}

.slick-next:before, .slick-prev:before{
  color: #4c4c4c;
  font-size: 30px;
}

.slick-next, .slick-prev{
  z-index: 999;
}

.slick-track{
  float: left;
  padding: 1em 0;
}
.slick-slider{
  @media (max-width: 768px){
  }
}
.slick-list{
  margin-left: .15em;
  @media (max-width: 768px){
  }
  .slick-slide {
    .card {
      display: block;
      min-height: 200px;
      width: 90%;
    }
  }
}

.slick-dots{
  padding-bottom: 1em;
  position: inherit;
  bottom: 0;
}

.home-carousel{
  padding: 0;
}
