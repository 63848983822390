.side-nav{
  border-right: 2px solid #dadada;
  float: left;
  overflow-x: hidden;
  padding: 8px 0;
  position: fixed;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  width: 225px;
  z-index: 0;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  &.display-none{
    display: none
  }
  &.reactive-toc{
    a{
      padding: 6px 8px 6px 16px;
      text-decoration: none;
      font-size: 15.5px;
      display: block;
      line-height: 1.2em;
      @media (min-height: 700px){
        padding: 6.5px 8px 6.5px 16px;
        line-height: 1.2em;
      }
      @media (min-height:800px){
        padding: 8px 8px 8px 16px;
        line-height: 1.4em;
      }
    }
    .main-header{
      font-weight: bold;
    }
    .sub-header{
      padding-left: 40px;
    }
    .current{
      border-right-color: #3279a3;
      border-right-width: 4px;
      border-right-style: solid;
    }
  }
  a{
    display: block;
    font-size: 15.5px;
    padding: 7px 8px 7px 16px;
    text-decoration: none;
    line-height: 1.5em;
  }
  .main-header{
    font-weight: bold;

  }
  .sub-header{
    font-weight: normal;
    padding-left: 40px;
  }
  .current{
    border-right-color: #3279a3;
    border-right-width: 4px;
    border-right-style: solid;
  }
}

@supports(position:sticky){
  .side-nav{
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
  }
}
