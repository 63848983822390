button{
  background-color: transparent;
  border: 0;
  &:hover {
    background-color: transparent;
  }
}

.large-round-btn{
  border-color: $blueLinkColor;
  border-radius: 100px;
  bottom: 20px;
  height: 60px;
  opacity: .9;
  position: fixed;
  right: 30px;
  width: 60px;
  z-index: 99;
  &.back-to-top{
    box-shadow: -3px -3px 3px 0 rgba(0,0,0,.19), 3px 0 3px 0 rgba(0,0,0,.19), 0 3px 3px 0 rgba(0,0,0,.23);
  }
}
.sml-round-btn{
  border-radius: 100px;
  cursor: pointer;
  height: 50px;
  padding: 0;
  text-align: center;
  width: 50px;
}
.inline-btn {
  display: inline-block;
  cursor: pointer;
}
.text-extend-button {
  background-color: #fff;
  .float-right{
    margin-right: 1em;
  }
  @media only screen and (max-width: 768px) {
    display: inline;
    .float-right{
      float: left;
      padding: 1em 0 0 1em;
    }
  }
  @media only screen and (max-width: 410px){
    .float-right{
      .inline-btn{
        display: block;
        &.round-dropdown-container{
          margin-left: 0.25em;
          .inline-right-txt{
            margin-left: 1em;
          }
        }
      }
    }
  }
  .inline-right-txt {
    background-color: inherit;
    color: inherit;
  }
}

button:focus {
  outline: 0;
}
button.link{
  @extend a;
  background-color: transparent;
  margin: 0;
  padding: 0;
  &:hover {
    @extend a:hover;
    background-color: transparent;
  }
}
.round-dropdown-container {
  .sml-round-btn{
    margin: 0;
    padding: 0;
  }
  .btn-dropdown {
    .sml-round-btn {
      @extend a;
      padding: 0;
      margin: 0;
      width: inherit;
    }
  }
}
.btn {
  background-color: $blueLinkColor;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: .9em;
  font-weight: bold;
  margin: 0.5em;
  padding: .75em;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  &:hover {
    background-color: $dkBlueLinkColor;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
}
.large-round-btn{
  bottom: 20px;
  height: 60px;
  line-height: 60px;
  opacity: .9;
  padding: 0;
  position: fixed;
  right: 30px;
  width: 60px;
  z-index: 99;
}
.sml-round-btn {
  border-radius: 200px;
  height: 50px;
  width: 50px;
}
.filter-nav {
    .current {
      background-color: #72b1ce;
      color: $dkBlueLinkColor;
      box-shadow: 0 8px 14px rgba(0,0,0,0.25), 0 6px 10px rgba(0,0,0,0.22);
    }
    .not-current{
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  pointer-events: none;
}
