
.home {
  .col-1090-container {
    img {
      @media only screen and (max-width: 530px) {
        margin: 0 auto;
        width: 30%;
      }
    }
  }
  .contact-us {
    background-attachment: fixed;
    background-image: url('/images/blurry-texture.jpg');
    background-position: top;
    background-size: cover;
    color: #fff;
    min-height: 300px;
    h3, a{
      color: #fff;
    }
    .img-width-80{
        width: 90%;
      @media (max-width: 823px){
        width: 20%
      }
    }
  }

}
