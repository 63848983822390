.dropdown-btn {
  border: none;
  cursor: pointer;
  padding: 1em;
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: none;
  min-width: 300px;
  padding: 1em 0;
  position: absolute;
  text-transform: uppercase;
  z-index: 1000;
}
.dropdown-content a {
  display: block;
  line-height: 1em;
  padding: .5em 1em;
  text-decoration: none;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.round-dropdown-container{
  background-color: #ffff;
  border-radius: 24px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
  cursor: pointer;
  display: inline-block;
  padding: .10em;
  position: relative;
  z-index: 100;
  &:focus {
    outline: 0;
  }
  p{
    margin-right: 0.75em;
    svg{
      font-size: xx-small;
      margin-left: 0.60em;
    }
  }
  .round-dropdown-content{
    background-color: #fff;
    border-radius: 17px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
    cursor: pointer;
    margin: 0.5em 1em 0 0;
    padding: 1em 0.5em 1em 0em;
    position: absolute;
    left: 0;
    width: 116px;
    z-index: 100;
    @media only screen and (max-width: 768px) {
      right: auto;
    }
    @media only screen and (max-width: 770px) {
      width: 110px;
    }
    &:focus {
      outline: 0;
    }
    .round-dropdown-click {
      &:focus {
        outline: 0;
      }
    }
    .sml-round-btn{
      line-height: 30px;
      margin-left: .25em;
      svg{
        width: 30px;
      }
    }
  }
  .display-none{
    display: none;
  }
}

#chart-dropdown{
  margin: 2em 0;
  text-align: center;
  p{
    display: block;
    font-weight: bold;
    margin: 0;
    padding: 0 .5em 0 0;
  }
  .rectangle-dropdown-container{
    display: inline-block;
    position: relative;
    .dropdown-side-text{
      margin-top: 0.5em;
      a{
        font-size: smaller;
      }
    }
    .rectangle-dropdown-button{
      border-radius: 5px;
      color: #fff;
      font-size: .9em;
      padding: 1em;
      min-width: 13em;
      cursor: pointer;
      svg{
        margin-left: .5em;
      }
    }
    .rectangle-dropdown-content{
      background-color: #f1f1f1;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      margin: 0 auto;
      margin-left: auto;
      margin-right: auto;
      max-height: 290px;
      left: 0;
      overflow: auto;
      position: absolute;
      right: .5em;
      text-align: left;
      width: 100%;
      z-index: 999;
      a{
        cursor: pointer;
        display: block;
        padding: 12px 16px;
        text-decoration: none;
      }
      @media only screen and (max-width:1060px){
        right: 70px;
      }
    }
    .display-none{
      display: none;
    }
  }
}
