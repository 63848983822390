.page-not-found{
  margin-left: 0.5em;
  padding: 6em 0;
  p{
    color: #fff;
    font-size: larger;
  }
  .btn{
    margin: 2em 1.5em;
    width: 15em;
  }
  @media only screen and (max-width: 1000px){
    h2{
      margin-top: 2em;
      margin-bottom: 0em;
    }
    .btn-container{
      display: inherit;
      margin: 0 auto;
        .btn{
          margin: 1em 1.5em;
        }
    }
  }
}
