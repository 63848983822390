.header-container {
  .cta-block-container {
    background-color: #fff;
    border: 1px #dadada solid;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: .5em;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 16px 4px rgba(0, 0, 0, 0.22);
      transform: translate(0, -6px);
    }
    h3{
      padding: 0 .75em;
    }
    p {
      color: #555;
      font-size: .8em;
      line-height: 1.45em;
      padding: 0 1em;
    }
  }
}
.card {
  background-color: #fff;
  border: 1px #dadada solid;
  display: block;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  float: left;
  height: 325px;
  margin: .5em;
  max-height: 350px;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @supports(display: grid) {
    height: auto;
  }
  &:hover {
    box-shadow: 0 1px 7px rgba(0,0,0,.25), 1px 4px 12px 4px rgba(0,0,0,.22);
    text-decoration: none;
    transform: translate(0, -6px);
  }
  h3{
    font-size: 1.12em;
    padding: 0 .75em;
  }
  p {
    color: #555;
    font-size: .8em;
    line-height: 1.45em;
    padding: 0 1em;
  }
  &.vertical-card {
    h3{
      padding-top: .75em;
    }
    img {
      font-family: 'object-fit: cover;';
      height: 200px;
      object-fit: cover;
      width: 100%;
    }
    svg {
      border: 2px solid #fff;
      border-radius: 60px;
      bottom: 0;
      right: 0;
      height: 20px;
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      padding: .5em;
      position: absolute;
      width: 20px;
    }
    &.related-content{
      min-height: 330px;
    }
  }
  &.small-horizontal-card {
    height: 120px;
    overflow: hidden;
    position: relative;
    @supports (display: grid) {
      width: 100%;
    }
    @media (max-width: 823px) {
      position: relative;
      margin: 0.5em 0 0 0;
    }
    &:after{
        content: normal;
    }
    .col-full {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: inherit;
      position: absolute;
      width: 100%;
      @supports(display: grid) {
      }
      &:first-child{
        &:before{
          background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55));
          bottom: 0;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
        }
      }
      @media (max-width: 823px) {
        height: 120px;
        width: 100%;
      }
    }
    h3{
      color: #fff;
      font-size: larger;
      margin: 0;
      padding: 1em;
      text-shadow: 0 0 20px rgb(0, 0, 0);
    }
  }
  img {
    font-family: 'object-fit: cover;';
    height: 100%;
    object-fit: cover;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }
}
.category-all {
  .react-reveal {
    display: grid;
  }
}
.home-carousel, #programs_and_resources{
  .slick-slider{
    // .slick-cloned {
    //   display: none;
    // }
    .resources {
      align-items: center;
      display: flex;
      position: relative;
      text-align: center;
      height: 200px;
      @media (max-width: 821px) {
        height: 350px;
      }
      p {
        color: #555;
        font-size: .9em;
      }
      .card-content {
        margin: 0 auto;
      }
    }
    img {
      padding: .5em; 
    }
    .card-content {
      padding: 0 .5em;
    }
    h3{
      margin-bottom: .25em;
      padding: 0;
    }
    h3, p{
      font-size: 1.17em;
      text-align: center;
    }
    img{
      display: flex;
      margin:auto;
      height: auto;
      width: inherit;
    }
    svg {
      background-color: #f1f1f1;
      border: 2px solid #fff;
      border-radius: 60px;
      fill: #fff;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 20px;
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      padding: .5em;
      width: 20px;
    }
  }
}
#programs_and_resources {
  .slick-slider {
    .slick-cloned {
      @media (max-width: 821px) {
        display: block;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 766px) {
        display: block;
      }
    }
    .resources {
      align-items: center;
      display: flex;
      position: relative;
      text-align: center;
      height: 400px;
      @media (max-width: 821px) {
        height: 350px;
      }
    }
  }
}

.links-block {
  background-color: $ltGray;
  border: 1px #dadada solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 8% auto;
  min-height: 100px;
  padding: .5em;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  line-height: 27px;
  text-align: center;
  width: 90%;
  h3{
    margin-top: 1.5em;
  }
  &:last-child {
    padding-bottom: 3em;
  }
}

.browse-by-card{
  display: block;
  height: 300px;
  padding: 1em 0;
  img{
    text-align: center;
    margin: 0 auto;
    width: 30%;
  }
}
@supports (display: grid) {
  .browse-by-card {
    height: auto;
  }
}
