.detail{
  header, .breadcrumbs-container, .text-extend-button, .cta-block{
    position: relative;
    z-index: 1;
  }
  .draft-status {
    background-color: #d68e04;
    color: #fff;
    opacity: .90;
    padding: 0em 1em .5em 1em;
    position: fixed;
    text-align: center;
    top: 0;
    width: calc(100% - 2em);
    z-index: 200;
    a, h2 {
      color: #fff;
    }
  }
}

.main-body{
  line-height: 1.8em;
  margin-left: 250px;
  padding: 0 10px;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0 20px;
  }
  .beb-footer-background, .beb-footer-image {
    display: none;
  }
  img{
    display: block;
    margin: 1.5em auto;
    max-width: 100%;
  }
  .card{
    img{
      margin: 0;
    }
  }

  figure{
    border: 1px solid #E1E1E1;
    margin: 0;
    padding: 1em 2em;
    h3{
      font-size: 1em;
      margin-bottom:0.5em;
    }
  }
  .sidebar {
    border: 1px solid #dadada;
    padding: 1em 2em;
  }
  .disclaimer {
    font-size: .8em;
    font-style: italic;
    line-height: 1.75em;
    padding-bottom: 1.5em;
  }
  .row-wrapper {
    border-top: 1px solid #dadada;
    display: block;
    padding-top: 1em;
    width: 100%;
    .card {
      padding: 1em;
      text-align: center;
      width: calc(33.3% - 3.25em);
      @media (max-width: 1366px){
        min-height: 80px;
      }
      @media (max-width: 1025px){
        min-height: auto;
        width: calc(100% - 3.25em);
      }
    }
    &:after {
      display: block;
      clear: both;
      content: '';
    }
  }
  #copy-right {
    display: none;
  }

  #endUseCharts{
    text-align: center;
    margin: 2em 0;
    .gas-chart, .electric-chart{
      margin: 0;
      display: inline;
      @media (min-width: 1440px){
        max-width: 50%;
      }
    }
    .col-2 {
      .gas-chart, .electric-chart{
        margin: 0 auto;
        max-width: 100%;
        @media (min-width: 1440px){
          max-width: 100%;
        }
      }
    }
  }
}
