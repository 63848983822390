@media only screen and (max-width: 500px){
.cta-block{
    display: block;
    h3{
      margin-bottom: 2em
    }
    .btn{
      display: block;
      margin: 1em auto;
      max-width: 12em;
    }
  }
}
