
figure {
  table {
    padding: 1em .5em;
    margin: 0 auto;
    background-color: #fff;
    text-align: left;
  }
  thead > tr > th, th{
    background-color: #777;
    color: white;
    font-weight: 500;
    line-height: 1.3em;
    padding: .5em;
    vertical-align: bottom;
  }
  .table > tbody > tr > td{
    padding: .5em;
    vertical-align: middle;
  }
  .table-vertical-borders > tbody > tr > td {
    border-right: solid 1px #ccc;
    border-left: solid 1px #ccc;
    &:first-child{
      border-left: none;
    }
    &:last-child{
      border-right: none;
    }
  }
  td {
    padding: .5em;
  }
  .table-hover > tbody > tr:hover, .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f1f1f1;
  }
  thead ~ tbody > tr:nth-of-type(odd) {
    background-color: white !important;
  }
  thead ~ tbody > tr:nth-of-type(even){
    background-color: #f1f1f1;
  }
  tfoot {
    font-size: 12px;
    background-color: #ffffff;
    line-height: 1.5em;
  }
  tfoot > tr > td{
    text-align: left;
    width: 155px;
    word-wrap: break-word;
  }
  // TABLE SPANNER
  .table > .table-spanner > tr > th, .table .table-spanner th{
    background-color: #f1f1f1;
    color: black;
    font-weight: 400;
    text-align: center;
  }
  .table-spanner .table-span div{
    border-bottom: 1px solid black;
    margin: 0 auto;
    padding: 1em 0 .5em 0;
    text-align: center;
    width: 75%;
  }
  .table-spanner:first-child .table-span div{
    margin: 0 auto;
    padding: 0;
    border-bottom: 1px solid black;
    text-align: center;
    width: 75%;
  }
  .table-spanner > tr > td {
    font-size: 14px;
    text-align: center;
  }
  .multi-row-white-stripe{
    background-color: white;
  }
  .multi-row-stripe{
    background-color: #f1f1f1;
  }
  .table-spanner > tr > .multi-subspan-header-left{
    text-align: left;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  iframe {
    margin: 1em auto;
  }
  .table-container-outer {
   position: relative;
  }
  .table-container {
     width: 100%;
     overflow-y: auto;
  }
}
