#search {
  background-color: $ltGray;
  border: 3px solid #9b9b9b;
  border-radius: 20px;
  box-shadow: none;
  color: black;
  cursor: text;
  font-size: .8em;
  height: 35px;
  margin: 1em;
  opacity: .8;
  outline: none;
  padding-left: 15px;
  position: absolute;
  right: 0;
  width: 275px;
  z-index: 100;
  @media(max-width: 720px){
    float: none;
    position:relative;
    width: 50%;
  }
}

#search-button{
  border-color: transparent;
  border-radius: 100px;
  margin-top: 1em;
  margin-right: 1em;
  padding: .55em 1em .55em 1em;
  position: relative;
    @media(min-width: 720px){
      display: none;
    }
  }

.search {
  .search-container{
    margin: 0.5 auto;
    text-align: center;
    #search{
      display: inline-block;
      margin: 1em 0;
      position: inherit;
      width: 325px;
      @media(max-width: 720px){
        float: none;
        position:relative;
        width: 65%;
      }
    }
    #search-button{
      border-radius: 100px;
      border-color: transparent;
      margin-left: 1em;
      margin-right:0;
      margin-top:0;
      position: relative;
      width: 50px;
    }
  }
  button{
    margin: 1em 0;
    width: 100%;
  }
  .main-body {
    margin: 0 auto;
    width: 900px;
    @media screen and (max-width:900px) {
      width: 80%;
    }

    .total-results{
      border: 1px solid #dadada;
      font-size: 20px;
      font-weight: bold;
      padding: 1em;
    }
    .list-row{
      border-top: 1px solid #dadada;
      padding: 1em 0 .5em 0;
      h3 {
        margin: 0;
      }
      p{
        margin: 0;
      }
      &:first-child {
        border-top: 0;
      }
    }
  }
}
