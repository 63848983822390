$primary-color: #cecece;

.full-page-loader{
 .loader-active{
  left: 47%;
  margin: 0;
  position: fixed;
  top: 45%;
  width: auto;
 }
}

.loader-active {
  display: block;
  margin: 9em auto;
  text-align: center;
  width: 50%;
}
