.modal-overlay{
  background-color: rgba(0, 0, 0, 0.30);
  bottom: 0px;
  left: 0px;
  overflow-y: scroll;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 999;
}

.modal-content{
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  height: 70%;
  height: calc(100% - 13em);
  margin: 4% auto;
  max-width: 45em;
  outline: none;
  overflow-y: scroll;
  padding: 3em 3em 1em 3em;
  position: relative;
  h2{
    line-height: 1.5em;
  }
  p{
    line-height: 2em;
  }
  button{
    display: block;
    margin: 2em auto;
    width: 15em;
  }
  @media (max-width: 1280px) {
    max-width: 40em;
  }
  @media (max-width: 980px){
    max-width: 35em;
  }
  @media (max-width: 800px){
    height: 90%;
    height: calc(100% - 6em);
    margin: 0;
    max-width: none;
    overflow-y: auto;
    padding: 3em 4.5em;
  }
  @media (max-width: 500px){
    padding: 3em 1.25em;
  }
}

.modal-content-share{
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  margin: 2.5em auto 1em auto;
  max-width: 37em;
  max-height: 100%;
  outline: none;
  overflow: scroll;
  padding: 2.5em 4em;
  position: relative;
  &.modal-confirmation{
    overflow: hidden;
    text-align: center;
    padding: 2.5em 2.5em;
    button{
      width: 10em;
    }
  }
  h2{
    margin-bottom: 1.5em;
  }
  button{
    display: block;
    margin: 3em auto 0 auto;
    width: 35em;
  }
  label{
    display: block;
    margin-bottom: 1.6em;
    p{
      margin: 0;
      padding: 0;
    }
    span{
      font-style: italic;
    }
    textarea, input {
      color: #555;
      box-shadow: inset 0 1px 2px rgba(142, 142, 142, 0.07);
      width: 35em;
      -webkit-box-shadow: inset 0 1px 2px rgba(142, 142, 142, 0.07);
    }
    textarea{
      border: 1px solid #8e8e8e;
      height: 5em;
      line-height: 30.7px;
      margin-bottom: 0.5em;
    }
    .errorMessage{
      color: #ec5840;
    }
    #subject{
      margin-bottom: 0.5em;
    }
  }
  .address-preview{
    text-align: center;
    padding-top: 2em;
    font-style: italic;
  }
  #g-recaptcha{
    margin: 2.5em auto;
  }
  @media (max-width: 800px){
    height: 100%;
    margin:0;
    max-height: none;
    label{
      textarea, input{
        width: 30em;
      }
    }
    button{
      width: 30em;
    }
  }
  @media (max-width: 650px){
    height: 100%;
    max-height: none;
    margin:0;
    label{
      textarea, input{
        width: 25em;
      }
    }
    button{
      margin: 0 auto;
      width: 25em;
    }
  }
  @media (max-width: 550px){
    height: 100%;
    max-height: none;
    margin:0;
    label{
      textarea, input{
        width: 20em;
      }
    }
    button{
      width: 20em;
    }
  }
  @media (max-width: 460px){
    height: 100%;
    max-height: none;
    margin:0;
    padding: 1em;
    h2{
      margin-bottom: 1em;
      margin-top: 2em;
    }
    label{
      textarea, input{
        width: 15em;
      }
      text-area{
        line-height: 1.5em;
      }
      a{
        font-size: smaller;
      }
    }
    button{
      width: 20em;
    }
  }
}
//to prevent scrolling of parent element
.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
