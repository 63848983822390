
header {
  display: table;
  min-height: 300px;
  position: relative;
  text-align: center;
  top: -1em;
  width: 100%;
  @supports(display: grid) {
    display: inherit;
    top: inherit;
  }
  .header-container{
    display: table-cell;
    margin: 0 auto;
    min-height: 300px;
    vertical-align: middle;
    @supports(display: grid) {
      display: grid;
      align-items: center;
      margin: 0;
    }
    @media(max-width: 770px){
      padding-bottom: 2em;
    }
    @media(max-width: 720px){
      min-height: 200px;
    }
  }
  .width-60{
    min-height: 200px;
    text-align: center;
  }
  h1 {
    margin: 0 auto;
    padding: .75em 1.5em .5em 1.5em;
    text-shadow: 0 0 120px rgba(0,0,0,0.75);
    text-transform: capitalize;
    width: 60%;
    @supports(display: grid) {
      width: inherit;
    }
    @media(max-width: 1024px){
      width: 60%;
    }
  }
  h1, h2{
    color: #fff;
  }
  h2 {
    font-size: 1.3em;
    font-weight: 700;
    line-height: 1.4em;
    width: 100%;
    @media screen and (max-width:580px) {
      font-size: 120%;
    }
  }
  h3{

  }
}
.home {
  .header {
    min-height: 650px;
    h1{
      border: 0;
      display: block;
      margin-bottom: .5em;
      margin-top: 1.5em;
      padding: 0;
      width: 80%;
      @media screen and (max-width:658px) {
        margin-top: 1em;
      }
      &:after {
        border-bottom: 2px solid #fff;
        content: ' ';
        display: block;
        margin: .5em auto;
        opacity: .5;
        width: 40%;
      }
    }
    img {
      margin: 0 auto;
      padding: .5em 0;
      width: 30%;
      height: auto;
    }
  }
  .header-container{
    display: flex;
    align-self: center;
  }
}

.detail{
  .header{
    h1{
      text-transform: inherit;
    }
  }
}
@supports (display: grid) {
  header {
    .header-container{
      display: grid;
      align-items: center;
    }
  }
  .home {
    .header{
      .cta-block {
        display: grid;
      }
    }
  }
}
