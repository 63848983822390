@media print {
    .no-print {
      display: none;
    }
    .detail .main-body{
      margin-left: 0;
    }
    .detail .header {
      height: 150px; 
  
      h1 {
        text-shadow: none;
        font-size: 2em;
      }
    }
    header, header .header-container{
      min-height: 150px;
    }
    .container-centered {
      width: 90% !important;
    }
    a {
      text-decoration: underline !important;
    }
    .main-body {
      line-height: 1.5 !important;
    }
    h1, h2, h3, h4, h5 {
      font-family: 'Times New Roman', Times, serif !important;
    }
  }