
.img-width-90{
  width: 90%;
}
.img-width-80{
  width: 80%;
}
.img-width-70{
  width: 70%;
}
.img-width-60{
  width: 60%;
}
.img-width-50{
  width: 50%;
}
.img-width-40{
  width: 40%;
}
.img-width-20{
  width: 20%;
}
.detail {
  img {
    margin: 0 auto;
    @media screen and (max-width:560px) {
      flex-direction: inherit;
      height: inherit;
      min-height: auto;
      width: 100%;
    }
  }
}
