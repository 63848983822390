.logo.float-left{
  margin-right: 2em;
  padding-top: .75em;
  text-align: center;
  @media screen and (max-width:1024px) {
    float: none;
    display: flex;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
  }
  @media screen and (max-width:560px) {
    height: auto;
  }
  img {
    @media screen and (max-width:1024px) {
      margin: auto;
    }
  }
  a {
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: center;
    @media screen and (max-width:1024px) {
      margin: auto;
    }
    @media screen and (max-width:560px) {
      flex-direction: inherit;
      height: inherit;
    }
  }
}

nav {
  ul{
    list-style: none;
    margin: 0;
    padding: .5em 0;
  }
  li {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 0 .5em .5em .5em;
    svg {
      margin-left: .5em;
      opacity: .7;
    }
  }
  .breadcrumbs {
    background-color: $ltGray;
    border-bottom: 1px solid #dadada;
    list-style: none;
    padding: .25em .75em;
    li {
      display: inline;
      font-size: .7em;
      font-weight: normal;
      text-transform: inherit;
    }
    a.active {
      color: $dkBlueLinkColor;
      cursor: default;
    }
    li+li:before {
      font-size: .85em;
      padding: .75em .75em .75em .25em;
      color: black;
      content: ">";
    }
  }
}

.nav-container{
  nav{
    &.main-nav{
      position: relative;
      z-index: 2;
    }
  }
}

.main-nav {
  background-color: #fff;
  box-shadow: -3px -3px 3px 0 rgba(0, 0, 0, 0.19), 3px 0 3px 0 rgba(0, 0, 0, 0.19), 0 3px 3px 0 rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -3px -3px 3px 0 rgba(0, 0, 0, 0.19), 3px 0 3px 0 rgba(0, 0, 0, 0.19), 0 3px 3px 0 rgba(0, 0, 0, 0.23);
  font-size: .9em;
  height: 90px;
  line-height: 70px;
  padding: 0 1em;
  ul{
    @media screen and (min-width:1025px) {
      display: block;
    }
    @media screen and (max-width:1024px) {
      display: none;
    }
  }
  li {
    display: inline-block;
    margin: .25em 0;
  }

  @supports not(display:flex){
    a{
      display: block;
    }
    .dropdown-business{
      display: block;
      float: left;
    }
    .dropdown-technologies{
      display: block;
      float: left;
    }
  }
  @supports (display: flex){
    .dropdown-business{
      display: flex;
      flex-direction: column;
    }
    .dropdown-technologies{
      display: flex;
      flex-direction: column;
    }
  }
  .display-none{
    display: none;
  }
}
.filter-nav {
  margin: .5em 0;
  @media (max-width: 823px){
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .desktop-only {
    @media (max-width: 823px){
      display: none;
    }
    li {
      padding: 0 .25em .5em .5em;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .mobile-only {
    @media (min-width: 824px){
      display: none;
      }
      h3{
        background: white;
        margin: 0;
        padding-bottom: .5em;
        padding-top: .5em;
      }
      .btn{
        border-radius: 0;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        margin: 0;
        padding: 1.5em;
        width: 100%;
        svg{
            margin-left: .5em;
            font-size: x-small;
        }
      }
      .display-none{
        display: none;
      }
      .filter-contents{
        background-color: #f1f1f1;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        min-height: 15em;
        position: absolute;
        width: 100%;
        z-index: 1;
        ul{
          padding: 1em 2em;
          li{
            display: block;
            padding: 0.65em 0;
            a{
              font-size: small;
            }
            .selected{
              opacity: 0.4;
            }
          }
        }
      }
  }
}
.mobile-navigation-container{
  position: absolute;
  .mobile-navigation{
    padding: 1em;
    width: calc(100% - 2em);
    &.main-nav {
      background-color: transparent;
      line-height: 1em;
      box-shadow: none;
    }
    @media screen and (min-width:1025px) {
      display: none;
    }
    @media screen and (max-width:1024px) {
      display: block;
    }
    .toggle-btn {
      line-height: 40px;
      height: 55px;
      svg{
        position: relative;
        z-index: 150;
      }
    }
    ul{
      list-style: none;
      display: block;
      height: 100%;
    }
    li{
      display: block;
      margin: .5em 0;
    }
    a {
      display: block;
      padding: .5em 0;
      @media (max-height: 411px){
        padding: .25em 0;
      }
    }
    nav {
      height: 100%;
      left: 0;
      max-width: 400px;
      padding-left: 1em;
      position: fixed;
      top: 0;
      transition-timing-function: ease-in;
      transition: 2s;
      width: 60%;
      z-index: 200;

      &.home{
        animation: open .5s 1;
        font-size: inherit;
        line-height: inherit;
      }
      &.biz-type, &.tech {
        font-size: inherit;
        line-height: inherit;
        animation: openOther .5s 1;

      }
      &.hide {
        animation: close .4s 1;
        transition-timing-function: ease-in-out;
        transition: 2s;
        transform: translateX(0);
      }
    }
    .back-btn {
      bottom: 1em;
      position: absolute;
      left: 1em;
      @media (max-height: 411px){
        left: auto;
        right: 1em;
      }
    }
    .close-button{
      top: 1em;
      right: 1em;
    }
  }
}

@keyframes open {
  0% {
    transform: translateX(-400px);

  }
  100% {
    transform: translateX(0);

  }
}
@keyframes openOther {
  0% {
    transform: translateX(-300px);

  }
  100% {
    transform: translateX(0);

  }
}
.main-nav, .mobile-navigation {
  a.nav-view-all{
    border-top: 1px solid #dadada;
    margin-top: .5em;
    padding-top: 1em;
  }
}
#overlay{
  &.open {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    top: 0;
    z-index: 100;
  }
  &.hide{
    display: none;
  }
}
